<template>
  <div class="container main cf">
    <h2>产品展示</h2>
    <div class="main_left">
      <a href="#">营销门户 </a>
      <a href="#">电商零售</a>
      <a href="#">门店服务 </a>
      <a href="#">营销推广 </a>
      <a href="#">设计服务</a>
      <a href="#">客户运营</a>
      <img src="@/assets/images/02_wqpl.jpg" />
    </div>
    <div class="main_right">
      <ul class="right_list">
        <li>
          <a href="produce_minute.html">
            <img src="@/assets/images/pro1.png" />
            <p>实木照片墙 创意绿色小鸟墙贴</p>
            <p>￥400.00</p>
          </a>
        </li>
        <li>
          <a href="produce_minute.html">
            <img src="@/assets/images/pro2.png" />
            <p>全悦18框实木照片墙创意相片墙</p>
            <p>￥189.00</p>
          </a>
        </li>
        <li>
          <a href="#">
            <img src="@/assets/images/pro3.png" />
            <p>地中海客厅实木照片墙现代简约</p>
            <p>￥1500.00</p>
          </a>
        </li>
        <li>
          <a href="#">
            <img src="@/assets/images/pro4.png" />
            <p>椭圆相框照片墙家居饰品创意复古墙</p>
            <p>￥800.00</p>
          </a>
        </li>
        <li>
          <a href="#"
            ><img src="@/assets/images/pro5.png" />
            <p>浴室吸水防滑进门地垫卫生间丝脚垫</p>
            <p>￥500.00</p>
          </a>
        </li>
        <li>
          <a href="#">
            <img src="@/assets/images/pro6.png" />
            <p>可爱小脚丫浴室门口吸水防滑地垫</p>
            <p>￥250.00</p>
          </a>
        </li>
      </ul>
      <!-- 分页 -->
      
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.main_left {
  float: left;
  width: 261px;
  margin-right: 28px;
}
.main_left a {
  font-size: 12px;
  width: 261px;
  height: 48px;
  display: inline-block;
  text-align: center;
  line-height: 48px;
  background: #f2f2f2;
  margin-bottom: 2px;
}
.main_left img {
  width: 261px;
  margin-top: 20px;
}

.main_right {
  float: right;
  width: 910px;
}
.main_right .right_list li {
  width: 282px;
  height: 331px;
  border: 1px solid #f2f2f2;
  padding: 15px;
  display: inline-block;
  box-sizing: border-box;
  margin: 0 15px 20px 0;
  font-size: 13px;
}
.main_right .right_list img {
  width: 250px;
  height: 250px;
  margin-bottom: 10px;
}

</style>